@use "@angular/material" as mat;
@use "palette" as pal;

@include mat.core();

@import "../node_modules/angular-calendar/css/angular-calendar.css";
// Fonts
@import 'https://fonts.googleapis.com/css?family=Material+Icons';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500');

@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

@font-face {
  font-family: "Symbola";
  src: url("/assets/fonts/Symbola-Emoji.woff") format("woff"),
  url("/assets/fonts/Symbola-Emoji.ttf") format("ttf");
}

$dark-text: #190323;
$dark-primary-text: rgba($dark-text, 0.87);

$my-app-primary: mat.define-palette(pal.$primary-palette);
$my-app-accent: mat.define-palette(pal.$accent-palette, A200, A100, A400);
$my-app-warn: mat.define-palette(pal.$secondary-palette);

$my-app-theme: mat.define-light-theme(
                (
                        color: (
                                primary: $my-app-primary,
                                accent: $my-app-accent,
                                warn: $my-app-warn,
                        ),
                )
);

@include mat.all-component-themes($my-app-theme);

html, body {
  --primary-color: #afe2e3;
  --primary-lighter-color: #e7f6f7;
  --primary-darker-color: #96d6d8;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$dark-primary-text};
  overflow: hidden;
}

.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';

  .mat-badge-content {
    font-family: 'Open Sans';
  }
}

.hidden {
  display: none !important;
}

.mat-mdc-card-title, .mat-mdc-card-subtitle {
  display: block;
  margin: 0;
  width: 100%;
  height: 40px;
  text-align: center;
}

.mat-icon {
  -webkit-user-select: none;
  user-select: none;
  background-repeat: no-repeat;
  display: inline-block;
  fill: currentColor;
  height: 20px !important;
  width: 24px;
  overflow: hidden;
}

.custom-side-nav-header {
  .mat-expansion-indicator::after {
    color: white !important;
  }
}


.mat-drawer.mat-drawer-side {
  z-index: 1 !important;
}

.mat-drawer-content {
  z-index: auto !important;
}

.mat-drawer-container {
  z-index: auto !important;
}

body {
  .hljs {
    border: 1px solid #ececec;
  }

  &.darkMode {
    // atom-one-dark theme for highlight js
    .hljs {
      display: block;
      overflow-x: auto;
      padding: 0.5em;
      color: #abb2bf;
      background: #282c34;
      border: 1px solid rgba(118, 118, 118, 0.1);
    }

    .hljs-comment,
    .hljs-quote {
      color: #5c6370;
      font-style: italic;
    }

    .hljs-doctag,
    .hljs-keyword,
    .hljs-formula {
      color: #c678dd;
    }

    .hljs-section,
    .hljs-name,
    .hljs-selector-tag,
    .hljs-deletion,
    .hljs-subst {
      color: #e06c75;
    }

    .hljs-literal {
      color: #56b6c2;
    }

    .hljs-string,
    .hljs-regexp,
    .hljs-addition,
    .hljs-attribute,
    .hljs-meta-string {
      color: #98c379;
    }

    .hljs-built_in,
    .hljs-class .hljs-title {
      color: #e6c07b;
    }

    .hljs-attr,
    .hljs-variable,
    .hljs-template-variable,
    .hljs-type,
    .hljs-selector-class,
    .hljs-selector-attr,
    .hljs-selector-pseudo,
    .hljs-number {
      color: #d19a66;
    }

    .hljs-symbol,
    .hljs-bullet,
    .hljs-link,
    .hljs-meta,
    .hljs-selector-id,
    .hljs-title {
      color: #61aeee;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: bold;
    }

    .hljs-link {
      text-decoration: underline;
    }
  }
}

//mat-icon { opacity: 0 };

@include mat.all-legacy-component-themes($my-app-theme);


